import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
// import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
// import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

import TitleIcon from '@material-ui/icons/Title';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import MessageIcon from '@material-ui/icons/Message';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ListIcon from '@material-ui/icons/List';
import GroupIcon from '@material-ui/icons/Group';
import BlockIcon from '@material-ui/icons/Block';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

import chatImage from "../assets/icon.png";

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [expandedSingle, setExpandedSingle] = useState(false);
  const [expandedBulk, setExpandedBulk] = useState(false);
  const [expandedSetting, setExpandedSetting] = useState(true);
  const [expandedAdmin, setExpandedAdmin] = useState(false);

  const handleToggleSingle = () => {
    setExpandedSingle(!expandedSingle);
  };

  const handleToggleBulk = () => {
    setExpandedBulk(!expandedBulk);
  };

  const handleToggleSetting = () => {
    setExpandedSetting(!expandedSetting);
  };

  const handleToggleAdmin = () => {
    setExpandedAdmin(!expandedAdmin);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div onClick={drawerClose}>
      <List>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img src={chatImage} style={{ width: '40%' }} alt={process.env.REACT_APP_TITLE} />
          {/* <p>{process.env.REACT_APP_TITLE}</p> */}
        </div>
        <Divider />
        <ListItem>
        <IconButton onClick={handleToggleSetting}>
            <ExpandMoreIcon />
        </IconButton>
        Ferramentas
        </ListItem>
        {expandedSetting && (
          <>
            {/* <ListItemLink
              to="/"
              primary="Dashboard"
              icon={<DashboardOutlinedIcon />}
            /> */}
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge overlap="rectangular" badgeContent={connectionWarning ? "!" : 0} color="error">
                  <SyncAltIcon />
                </Badge>
              }
            />
            <ListItemLink
              to="/SingleGroupCreate"
              primary={i18n.t("mainDrawer.listItems.createGroup")}
              icon={<AddIcon />}
            />
            <ListItemLink
              to="/SingleGroupList"
              primary={i18n.t("mainDrawer.listItems.listGroup")}
              icon={<ListIcon />}
            /> 
            <ListItemLink
              to="/SingleGroupParticipantList"
              primary={i18n.t("mainDrawer.listItems.listParticipant")}
              icon={<GroupIcon />}
            /> 
            <ListItemLink
              to="/WordList"
              primary={i18n.t("mainDrawer.listItems.forbiddenWord")}
              icon={<BlockIcon />}
            /> 
            <ListItemLink
              to="/BanList"
              primary={i18n.t("mainDrawer.listItems.bannedNumbers")}
              icon={<VerifiedUserIcon />}
            /> 
            <ListItemLink
              to="/GreetingMessage"
              primary={i18n.t("mainDrawer.listItems.greetingsMessage")}
              icon={<NotificationsActiveIcon />}
            /> 
            <ListItemLink
              to="/FarewellMessage"
              primary={i18n.t("mainDrawer.listItems.farewellMessage")}
              icon={<NotificationImportantIcon />}
            /> 
            <ListItemLink
              to="/tickets"
              primary={i18n.t("mainDrawer.listItems.tickets")}
              icon={<WhatsAppIcon />}
            />
            <ListItemLink
              to="/contacts"
              primary={i18n.t("mainDrawer.listItems.contacts")}
              icon={<ContactPhoneOutlinedIcon />}
            />
            <ListItemLink
              to="/ParticipantsList"
              primary={i18n.t("mainDrawer.listItems.leads")}
              icon={<RecentActorsIcon />}
            />
            <ListItemLink
              to="/GhostList"
              primary={i18n.t("mainDrawer.listItems.ghostMention")}
              icon={<AlternateEmailIcon />}
            /> 
            <ListItemLink
              to="/quickAnswers"
              primary={i18n.t("mainDrawer.listItems.quickAnswers")}
              icon={<QuestionAnswerOutlinedIcon />}
            />
          </>
        )}
      <Divider />
      </List>
      <List>
        <ListItem>
        <IconButton onClick={handleToggleSingle}>
            <ExpandMoreIcon />
        </IconButton>
        Ações Individuais
        </ListItem>
        {expandedSingle && (
          <>
            <ListItemLink
              to="/SingleGroupChangeTitle"
              primary={i18n.t("mainDrawer.listItems.singleGroupChangeTitle")}
              icon={<TitleIcon />}
            />
            <ListItemLink
              to="/SingleGroupChangeDescription"
              primary={i18n.t("mainDrawer.listItems.singleGroupChangeDescription")}
              icon={<DescriptionIcon />}
            />
            <ListItemLink
              to="/SingleGroupChangePicture"
              primary={i18n.t("mainDrawer.listItems.singleGroupChangePicture")}
              icon={<ImageIcon />}
            />
            <ListItemLink
              to="/SingleGroupSetAdminOnly"
              primary={i18n.t("mainDrawer.listItems.singleGroupSetAdminOnly")}
              icon={<LockOpenIcon />}
            />
            <ListItemLink
              to="/SingleGroupPromote"
              primary={i18n.t("mainDrawer.listItems.singleGroupPromote")}
              icon={<ThumbUpIcon />}
            />
            <ListItemLink
              to="/SingleGroupDemote"
              primary={i18n.t("mainDrawer.listItems.singleGroupDemote")}
              icon={<ThumbDownIcon />}
            />
            <ListItemLink
              to="/SingleGroupAdd"
              primary={i18n.t("mainDrawer.listItems.singleGroupAdd")}
              icon={<PersonAddIcon />}
            />
            <ListItemLink
              to="/SingleGroupRemove"
              primary={i18n.t("mainDrawer.listItems.singleGroupRemove")}
              icon={<PersonAddDisabledIcon />}
            />
            <ListItemLink
              to="/SingleGroupSendMessage"
              primary={i18n.t("mainDrawer.listItems.singleGroupSendMessage")}
              icon={<MessageIcon />}
            />
            <ListItemLink
              to="/SingleGroupSendMedia"
              primary={i18n.t("mainDrawer.listItems.singleGroupSendMedia")}
              icon={<FileCopyIcon />}
            /> 
            <ListItemLink
              to="/SingleGroupSendVoice"
              primary={i18n.t("mainDrawer.listItems.singleGroupSendVoice")}
              icon={<RecordVoiceOverIcon />}
            /> 
          </>
        )}
      <Divider />
      </List>
      <List>
      <ListItem>
        <IconButton onClick={handleToggleBulk}>
            <ExpandMoreIcon />
        </IconButton>
        Ações em Massa
        </ListItem>
        {expandedBulk && (
          <>
            <ListItemLink
              to="/BulkGroupChangeTitle"
              primary={i18n.t("mainDrawer.listItems.bulkGroupChangeTitle")}
              icon={<TitleIcon />}
            />
            <ListItemLink
              to="/BulkGroupChangeDescription"
              primary={i18n.t("mainDrawer.listItems.bulkGroupChangeDescription")}
              icon={<DescriptionIcon />}
            />
            <ListItemLink
              to="/BulkGroupChangePicture"
              primary={i18n.t("mainDrawer.listItems.bulkGroupChangePicture")}
              icon={<ImageIcon />}
            />
            <ListItemLink
              to="/BulkGroupSetAdminOnly"
              primary={i18n.t("mainDrawer.listItems.bulkGroupSetAdminOnly")}
              icon={<LockOpenIcon />}
            />
            <ListItemLink
              to="/BulkGroupPromote"
              primary={i18n.t("mainDrawer.listItems.bulkGroupPromote")}
              icon={<ThumbUpIcon />}
            />
            <ListItemLink
              to="/BulkGroupDemote"
              primary={i18n.t("mainDrawer.listItems.bulkGroupDemote")}
              icon={<ThumbDownIcon />}
            />
            <ListItemLink
              to="/BulkGroupAdd"
              primary={i18n.t("mainDrawer.listItems.bulkGroupAdd")}
              icon={<PersonAddIcon />}
            />
            <ListItemLink
              to="/BulkGroupRemove"
              primary={i18n.t("mainDrawer.listItems.bulkGroupRemove")}
              icon={<PersonAddDisabledIcon />}
            />
            <ListItemLink
              to="/BulkGroupSendMessage"
              primary={i18n.t("mainDrawer.listItems.bulkGroupSendMessage")}
              icon={<MessageIcon />}
            />
            <ListItemLink
              to="/BulkGroupSendMedia"
              primary={i18n.t("mainDrawer.listItems.bulkGroupSendMedia")}
              icon={<FileCopyIcon />}
            /> 
            <ListItemLink
              to="/BulkGroupSendVoice"
              primary={i18n.t("mainDrawer.listItems.bulkGroupSendVoice")}
              icon={<RecordVoiceOverIcon />}
            /> 
        </>  
      )}
      <Divider />
      </List>
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <List>
              <ListItem>
              <IconButton onClick={handleToggleAdmin}>
                  <ExpandMoreIcon />
              </IconButton>
              Configurações
              </ListItem>
              {expandedAdmin && (
                <>
                  <ListItemLink
                    to="/users"
                    primary={i18n.t("mainDrawer.listItems.users")}
                    icon={<PeopleAltOutlinedIcon />}
                  />
                  {/* <ListItemLink
                    to="/queues"
                    primary={i18n.t("mainDrawer.listItems.queues")}
                    icon={<AccountTreeOutlinedIcon />}
                  /> */}
                  <ListItemLink
                    to="/settings"
                    primary={i18n.t("mainDrawer.listItems.settings")}
                    icon={<SettingsOutlinedIcon />}
                  />
                </>
              )}
            </List>
		{/* Adicionando a versão do sistema */}
      <List>
        <ListItem>
          <ListItemIcon>
            {/* Ícone para a versão (pode ser substituído por outro ícone, se preferir) */}
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={`Versão do Sistema: 1.0.0`} />
        </ListItem>
      </List>
			
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
