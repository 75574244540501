import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
// import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
// import Queues from "../pages/Queues/";
// SINGLE GROUP
import SingleGroupChangeTitle from "../pages/SingleGroupChangeTitle/";
import SingleGroupChangeDescription from "../pages/SingleGroupChangeDescription/";
import SingleGroupChangePicture from "../pages/SingleGroupChangePicture/";
import SingleGroupCreate from "../pages/SingleGroupCreate/";
import SingleGroupSetAdminOnly from "../pages/SingleGroupSetAdminOnly/";
import SingleGroupPromote from "../pages/SingleGroupPromote/";
import SingleGroupDemote from "../pages/SingleGroupDemote/";
import SingleGroupAdd from "../pages/SingleGroupAdd/";
import SingleGroupRemove from "../pages/SingleGroupRemove/";
import SingleGroupSendMessage from "../pages/SingleGroupSendMessage/";
import SingleGroupSendMedia from "../pages/SingleGroupSendMedia/";
import SingleGroupSendVoice from "../pages/SingleGroupSendVoice/";
import SingleGroupList from "../pages/SingleGroupList/";
import SingleGroupParticipantList from "../pages/SingleGroupParticipantList/";
import WordList from "../pages/WordList/";
import GhostList from "../pages/GhostList/";
import BanList from "../pages/BanList";
import GreetingMessage from "../pages/GreetingMessage";
import FarewellMessage from "../pages/FarewellMessage";
import ParticipantsList from "../pages/ParticipantsList";
// BULK GROUP
import BulkGroupChangeTitle from "../pages/BulkGroupChangeTitle/";
import BulkGroupChangeDescription from "../pages/BulkGroupChangeDescription/";
import BulkGroupChangePicture from "../pages/BulkGroupChangePicture/";
import BulkGroupSetAdminOnly from "../pages/BulkGroupSetAdminOnly/";
import BulkGroupPromote from "../pages/BulkGroupPromote/";
import BulkGroupDemote from "../pages/BulkGroupDemote/";
import BulkGroupAdd from "../pages/BulkGroupAdd/";
import BulkGroupRemove from "../pages/BulkGroupRemove/";
import BulkGroupSendMessage from "../pages/BulkGroupSendMessage/";
import BulkGroupSendMedia from "../pages/BulkGroupSendMedia/";
import BulkGroupSendVoice from "../pages/BulkGroupSendVoice/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              {/* <Route exact path="/" component={Dashboard} isPrivate /> */}
              <Route
                exact
                path="/tickets/:ticketId?"
                component={Tickets}
                isPrivate
              />
              <Route
                exact
                path="/connections"
                component={Connections}
                isPrivate
              />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route
                exact
                path="/quickAnswers"
                component={QuickAnswers}
                isPrivate
              />
              <Route exact path="/Settings" component={Settings} isPrivate />
              {/* <Route exact path="/Queues" component={Queues} isPrivate /> */}
              
              <Route exact path="/SingleGroupChangeTitle" component={SingleGroupChangeTitle} isPrivate />
              <Route exact path="/SingleGroupChangeDescription" component={SingleGroupChangeDescription} isPrivate />
              <Route exact path="/SingleGroupChangePicture" component={SingleGroupChangePicture} isPrivate />
              <Route exact path="/SingleGroupCreate" component={SingleGroupCreate} isPrivate />
              <Route exact path="/SingleGroupSetAdminOnly" component={SingleGroupSetAdminOnly} isPrivate />
              <Route exact path="/SingleGroupPromote" component={SingleGroupPromote} isPrivate />
              <Route exact path="/SingleGroupDemote" component={SingleGroupDemote} isPrivate />
              <Route exact path="/SingleGroupAdd" component={SingleGroupAdd} isPrivate />
              <Route exact path="/SingleGroupRemove" component={SingleGroupRemove} isPrivate />
              <Route exact path="/SingleGroupSendMessage" component={SingleGroupSendMessage} isPrivate />
              <Route exact path="/SingleGroupSendMedia" component={SingleGroupSendMedia} isPrivate />
              <Route exact path="/SingleGroupSendVoice" component={SingleGroupSendVoice} isPrivate />
              <Route exact path="/SingleGroupList" component={SingleGroupList} isPrivate />
              <Route exact path="/SingleGroupParticipantList" component={SingleGroupParticipantList} isPrivate />
              <Route exact path="/WordList" component={WordList} isPrivate />
              <Route exact path="/GhostList" component={GhostList} isPrivate />
              <Route exact path="/BanList" component={BanList} isPrivate />
              <Route exact path="/GreetingMessage" component={GreetingMessage} isPrivate />
              <Route exact path="/FarewellMessage" component={FarewellMessage} isPrivate />
              <Route exact path="/ParticipantsList" component={ParticipantsList} isPrivate />
              
              <Route exact path="/BulkGroupChangeTitle" component={BulkGroupChangeTitle} isPrivate />
              <Route exact path="/BulkGroupChangeDescription" component={BulkGroupChangeDescription} isPrivate />
              <Route exact path="/BulkGroupChangePicture" component={BulkGroupChangePicture} isPrivate />
              <Route exact path="/BulkGroupSetAdminOnly" component={BulkGroupSetAdminOnly} isPrivate />
              <Route exact path="/BulkGroupPromote" component={BulkGroupPromote} isPrivate />
              <Route exact path="/BulkGroupDemote" component={BulkGroupDemote} isPrivate />
              <Route exact path="/BulkGroupAdd" component={BulkGroupAdd} isPrivate />
              <Route exact path="/BulkGroupRemove" component={BulkGroupRemove} isPrivate />
              <Route exact path="/BulkGroupSendMessage" component={BulkGroupSendMessage} isPrivate />
              <Route exact path="/BulkGroupSendMedia" component={BulkGroupSendMedia} isPrivate />
              <Route exact path="/BulkGroupSendVoice" component={BulkGroupSendVoice} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
